export const PHONE_MASK = '+\\9\\9\\8\\ (99) 999 99 99'
export const PAST_BUG_PHONE = '+998 (+998'
export const PAST_BUG_8_PHONE = '+998 (+8'

export const PHONE_TITLE = '+998'

export const PHONE_MATCHES_REG =
  /\+998\s?\(?((33|55|77|88|90|91|93|94|95|97|98|99))\)?\s?\d{3}\s?\d{2}\s?\d{2}/
export const PHONE_MATCHES_ERR =
  'Проверьте код мобильного оператора!\n Доступные коды операторов: \n 33, 55, 77, 88, 90, 91, 93, 94, 95, 97, 98, 99 \n Пример: +998 (XX) XXX XX XX'
